import { useCallback, useEffect } from 'react';
import { isEmpty, isNil, noop, pickBy } from 'lodash-es';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { BackdropSpinner } from 'components/BackdropSpinner';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { ClientFragment } from '../fragments/ClientFragment';
import { DEFAULT_FORM_VALUES } from '../modules';
import { ConfigurationFragment } from '../fragments/ConfigurationFragment';
import { TicketConfigurationFragment } from '../fragments/TicketConfigurationFragment';
import { TestConfigurationFragment } from '../fragments/TestConfigurationFragment';

export const DefaultForm = ({
  initialValues = DEFAULT_FORM_VALUES,
  headline,
  applyButtonLabel,
  isLoading,
  isDisabled = isLoading,
  onApply,
  onAfterCancel = noop,
}) => {
  const scrollToTop = useScrollToTop();
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = useCallback(
    (formValues) => {
      const normalizedValues = pickBy(
        formValues,
        (value) => !isNil(value) || !isEmpty(value),
      );

      onApply(normalizedValues);
      scrollToTop();
    },
    [scrollToTop, onApply],
  );

  const cancelChanges = useCallback(() => {
    reset(initialValues);

    scrollToTop();
    onAfterCancel();
  }, [reset, scrollToTop, initialValues, onAfterCancel]);

  useEffect(() => reset(initialValues), [reset, initialValues]);

  return (
    <Paper>
      <Box p={2}>
        {/* Loader */}
        <BackdropSpinner isOpen={isLoading} />

        {/* The form */}
        {headline}

        {/* Form */}
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Box pt={1} pb={1}>
            {/* Client name */}
            <Box py={2}>
              <Typography variant="h6">Name</Typography>
            </Box>
            <ClientFragment control={control} isDisabled={isDisabled} />

            {/* Client's basic config */}
            <Box py={2}>
              <Typography variant="h6">Basics</Typography>
            </Box>
            <ConfigurationFragment control={control} isDisabled={isDisabled} />

            {/* Client's ticket config */}
            <Box py={2}>
              <Typography variant="h6">Ticket</Typography>
            </Box>
            <TicketConfigurationFragment
              control={control}
              isDisabled={isDisabled}
            />

            {/* Client's test config */}
            <TestConfigurationFragment
              control={control}
              isDisabled={isDisabled}
            />
          </Box>

          {/* Controls */}
          <Box pt={2} pb={1}>
            <Button
              color="primary"
              disabled={isDisabled}
              style={{ marginRight: '20px' }}
              type="submit"
              variant="contained"
            >
              {applyButtonLabel}
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              onClick={cancelChanges}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};
