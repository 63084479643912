import { Box, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(20)}px)`,
  },
}));

export const Layout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTabletUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Header />
      <Box w={1} p={2}>
        <Box
          display="flex"
          flexDirection="column"
          ml="auto"
          mr="auto"
          mt={3}
          width={isTabletUp ? theme.spacing(120) : 1}
        >
          <Box className={classes.root}>{children}</Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};
