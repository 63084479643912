import { Controller } from 'react-hook-form';
import { Chip, makeStyles, MenuItem, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Translate } from 'intl/Translate';
import countries from 'intl/countries_en.json';
import { FormField } from 'constants/FormField';
import { validateHex, validateURL } from '../modules';

const FIELDS = [
  'city',
  'countryCode',
  'dateOfBirth',
  'email',
  'firstName',
  'gender',
  'idNumber',
  'insuranceCardNumber',
  'lastName',
  'phone',
  'postalCode',
  'street',
  'swissInsuranceCardNumber',
];

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export const ConfigurationFragment = ({ control, isDisabled }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <>
      <Controller
        control={control}
        name={FormField.MAIN_COLOR}
        rules={{
          required: 'The theme color is required.',
          validate: validateHex,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            id={FormField.CONFIGURATION_MAIN_COLOR}
            InputLabelProps={{ shrink: true }}
            label="Theme color"
            margin="dense"
            placeholder="e.g. #aaabbb or #aaa"
            variant="outlined"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name={FormField.GTC_URL}
        rules={{
          required: 'The GTC URL is required.',
          validate: validateURL,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            id={FormField.CONFIGURATION_GTC_URL}
            InputLabelProps={{ shrink: true }}
            label="GTC URL"
            margin="dense"
            placeholder="e.g. http://any-gtg.com/page"
            variant="outlined"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name={FormField.PRIVACY_POLICY_URL}
        rules={{
          required: 'The privacy policy URL is required.',
          validate: validateURL,
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            id={FormField.CONFIGURATION_PRIVACY_POLICY_URL}
            InputLabelProps={{ shrink: true }}
            label="Privacy policy URL"
            margin="dense"
            placeholder="e.g. http://any-privacy-policy.com/page"
            variant="outlined"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name={FormField.FORM_FIELDS}
        rules={{
          required: 'The fields are required.',
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            id={FormField.FORM_FIELDS}
            select
            SelectProps={{
              multiple: true,
              renderValue: (options) => (
                <div className={classes.chips}>
                  {options.map((value) => (
                    <Chip
                      key={value}
                      label={intl.formatMessage({
                        id: `forms.input.${value}`,
                      })}
                      className={classes.chip}
                    />
                  ))}
                </div>
              ),
            }}
            fullWidth
            margin="dense"
            variant="outlined"
            label="Fields configuration"
            error={!!error}
            helperText={error?.message}
            {...field}
          >
            {FIELDS.map((fieldKey) => (
              <MenuItem key={fieldKey} value={fieldKey}>
                <Translate text={`forms.input.${fieldKey}`} />
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Controller
        control={control}
        name={FormField.DEFAULT_COUNTRY_CODE}
        rules={{
          required: 'Default country code is required.',
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            id={FormField.DEFAULT_COUNTRY_CODE}
            helperText={error?.message}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            label="Default country code"
            margin="dense"
            select
            variant="outlined"
            {...field}
          >
            {countries.map(({ name, code }) => (
              <MenuItem key={name} value={code}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      {/* https://github.com/keyper/cfp-id-backend/blob/master/CFP.ID.API/Models/Configuration/ConfigurationModel.cs#L23 */}
      <Controller
        control={control}
        name={FormField.SELF_CHECK_IN_REFERENCE}
        render={({ field, fieldState: { error } }) => (
          <TextField
            disabled={isDisabled}
            error={!!error}
            fullWidth
            helperText={error?.message}
            id={FormField.SELF_CHECK_IN_REFERENCE}
            InputLabelProps={{ shrink: true }}
            label="Self check-in reference (HPS organization ID)"
            margin="dense"
            variant="outlined"
            {...field}
          />
        )}
      />
    </>
  );
};
