import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useAddCfpForm } from 'hooks/useAddCfpForm';
import { DefaultForm } from 'components/DefaultForm';
import { RoutePath } from 'constants/RoutePath';

const BUTTON_LABEL = 'Add';
const HEADLINE = 'New config';

export const Add = () => {
  const { push: navigate } = useHistory();

  const navigateToEditPage = useCallback(
    () => navigate(RoutePath.ROOT),
    [navigate],
  );

  const { mutate, isLoading } = useAddCfpForm({
    onSuccess: navigateToEditPage,
  });

  return (
    <DefaultForm
      applyButtonLabel={BUTTON_LABEL}
      headline={<Typography variant="h4">{HEADLINE}</Typography>}
      isLoading={isLoading}
      onApply={mutate}
    />
  );
};
