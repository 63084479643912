import { useMutation } from 'react-query';
import { noop } from 'lodash-es';
import {
  ERROR_NOTIFICATION,
  SUCCESS_NOTIFICATION,
  useNotification,
} from 'components/notifications';
import { useApi } from './useApi';

export const useAddCfpForm = ({ onSuccess = noop }) => {
  const { post } = useApi();
  const { showNotification } = useNotification();

  return useMutation((payload) => post('form', payload), {
    onSuccess: () => {
      onSuccess();

      showNotification(SUCCESS_NOTIFICATION, 'forms.add.success');
    },
    onError: () => showNotification(ERROR_NOTIFICATION, 'forms.add.error'),
  });
};
