import { Controller } from 'react-hook-form';
import { Box, MenuItem, TextField } from '@material-ui/core';
import { FormField } from 'constants/FormField';
import { validateNotWhitespaceOnly } from 'components/modules';
import countries from 'intl/countries_en.json';

export const OrganizerConfigurationFragment = ({ control, isDisabled }) => (
  <Box display="flex" flexDirection="column" width={1}>
    <Controller
      control={control}
      name={FormField.ORGANIZER_EXTERNAL_ID}
      rules={{
        required: 'Organizer external ID is required.',
        validate: validateNotWhitespaceOnly,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.ORGANIZER_EXTERNAL_ID}
          InputLabelProps={{ shrink: true }}
          label="External Organizer ID"
          margin="dense"
          placeholder="e.g. cfp_dev_org"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.ORGANIZER_NAME}
      rules={{
        required: 'Organizer name is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.ORGANIZER_NAME}
          InputLabelProps={{ shrink: true }}
          label="Organizer Name"
          margin="dense"
          placeholder="e.g. Max Mustermann"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.ORGANIZER_CITY}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.ORGANIZER_CITY}
          InputLabelProps={{ shrink: true }}
          label="City"
          margin="dense"
          placeholder="e.g. München"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.ORGANIZER_STREET}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.ORGANIZER_STREET}
          InputLabelProps={{ shrink: true }}
          label="Street"
          margin="dense"
          placeholder="e.g. Postgasse 7"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.ORGANIZER_POSTAL_CODE}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.ORGANIZER_POSTAL_CODE}
          InputLabelProps={{ shrink: true }}
          label="Postal Code"
          margin="dense"
          placeholder="4142"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.ORGANIZER_COUNTRY_CODE}
      rules={{
        required: 'Organizer country code is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          id={FormField.ORGANIZER_COUNTRY_CODE}
          helperText={error?.message}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ displayEmpty: true }}
          label="Organizer country code"
          margin="dense"
          select
          variant="outlined"
          {...field}
        >
          {countries.map(({ name, code }) => (
            <MenuItem key={name} value={code}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  </Box>
);
