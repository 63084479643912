import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { AmplifyConfiguration } from 'constants/AmplifyConfiguration';
import App from 'App';
import './intl/amplify/amplifyIntl';

Amplify.configure(AmplifyConfiguration);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
