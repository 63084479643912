import { useParams } from 'react-router-dom';
import { first, isEmpty } from 'lodash-es';
import { Typography } from '@material-ui/core';
import { useFetchCfpForms } from 'hooks/useFetchCfpForms';
import { useUpdateCfpForm } from 'hooks/useUpdateCfpForm';
import { DefaultForm } from 'components/DefaultForm';
import { EMPTY_STRING } from 'constants/AppConstants';

const ERRONEOUS_HEADLINE = `We're sorry. We cannot identify the requested config.`;

export const Edit = () => {
  const { clientId } = useParams();

  const {
    isLoading: isFetchLoading,
    data,
    isError: isFetchFailed,
    isSuccess,
  } = useFetchCfpForms({
    queryParams: { search: clientId },
  });

  const existingFormConfig = first(data?.forms);

  const { mutate: mutateByUpdatingForm, isLoading: isMutateLoading } =
    useUpdateCfpForm(clientId);

  const isErroneous =
    isFetchFailed || (isSuccess && isEmpty(existingFormConfig));
  const isLoading = isFetchLoading || isMutateLoading;
  const defaultHeadline = (
    <>
      <Typography variant="h4">Editing</Typography>
      <Typography variant="h6">
        {existingFormConfig?.client?.clientName || EMPTY_STRING}
      </Typography>
    </>
  );

  return (
    <DefaultForm
      applyButtonLabel="Update"
      headline={isErroneous ? ERRONEOUS_HEADLINE : defaultHeadline}
      initialValues={existingFormConfig}
      isLoading={isLoading}
      isDisabled={isErroneous}
      onApply={mutateByUpdatingForm}
    />
  );
};
