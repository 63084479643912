import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { FormField } from 'constants/FormField';

export const ClientFragment = ({ control, isDisabled }) => (
  <Controller
    control={control}
    name={FormField.CLIENT_NAME}
    rules={{
      required: 'The client name is required.',
    }}
    render={({ field, fieldState: { error } }) => (
      <TextField
        autoFocus
        disabled={isDisabled}
        error={!!error}
        fullWidth
        helperText={error?.message}
        id={FormField.CLIENT_NAME}
        InputLabelProps={{ shrink: true }}
        label="Client name"
        margin="dense"
        placeholder="e.g. Mustermann GmbH"
        variant="outlined"
        {...field}
      />
    )}
  />
);
