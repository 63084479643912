export const FormField = {
  CLIENT_NAME: 'client.clientName',

  // ---
  // Main config
  // ---
  MAIN_COLOR: 'configuration.mainColor',
  GTC_URL: 'configuration.gtcUrl',
  PRIVACY_POLICY_URL: 'configuration.privacyPolicyUrl',
  FORM_FIELDS: 'configuration.formFields',
  DEFAULT_COUNTRY_CODE: 'configuration.defaultCountryCode',
  SELF_CHECK_IN_REFERENCE: 'configuration.selfCheckInReference',

  // ---
  // Ticket config
  // ---
  KEYPER_API_KEY: 'ticketConfiguration.keyperApiKey',
  BARCODE_MODE: 'ticketConfiguration.barcodeMode',
  SELF_CHECK_IN_URL: 'ticketConfiguration.selfCheckInUrl',
  PDF_HTML: 'ticketConfiguration.pdfHTML',

  // ---
  // Ticket config event
  // ---
  EXTERNAL_EVENT_ID: 'ticketConfiguration.configuration.event.externalId',
  EVENT_NAME: 'ticketConfiguration.configuration.event.name',
  DO_PERSONALIZATION:
    'ticketConfiguration.configuration.event.doPersonalization',
  OVERVIEW_IMAGE_URL:
    'ticketConfiguration.configuration.event.overviewImageUrl',
  ADDITIONAL_TEXT: 'ticketConfiguration.configuration.event.additionalText',

  // ---
  // Ticket config location
  // ---
  EXTERNAL_LOCATION_ID: 'ticketConfiguration.configuration.location.externalId',
  LOCATION_NAME: 'ticketConfiguration.configuration.location.name',
  CITY: 'ticketConfiguration.configuration.location.city',
  STREET: 'ticketConfiguration.configuration.location.street',
  POSTAL_CODE: 'ticketConfiguration.configuration.location.postalCode',
  COUNTRY_CODE: 'ticketConfiguration.configuration.location.countryCode',

  // ---
  // Ticket config organizer
  ORGANIZER_EXTERNAL_ID:
    'ticketConfiguration.configuration.organizer.externalId',
  ORGANIZER_NAME: 'ticketConfiguration.configuration.organizer.name',
  ORGANIZER_CITY: 'ticketConfiguration.configuration.organizer.city',
  ORGANIZER_STREET: 'ticketConfiguration.configuration.organizer.street',
  ORGANIZER_POSTAL_CODE:
    'ticketConfiguration.configuration.organizer.postalCode',
  ORGANIZER_COUNTRY_CODE:
    'ticketConfiguration.configuration.organizer.countryCode',

  // ---
  // Test configuration
  TEST_TYPE: 'testConfiguration.testType',
  ISSUE_DCC: 'testConfiguration.issueDcc',
};
