import { Backdrop, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const BackdropSpinner = ({ isOpen }) => {
  const classes = useStyles();

  return (
    <Box>
      <Backdrop
        className={classes.backdrop}
        data-testid={
          isOpen ? '@keyper-backdrop-open' : '@keyper-backdrop-closed'
        }
        open={Boolean(isOpen)}
      >
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};
