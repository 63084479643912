import { Box, IconButton } from '@material-ui/core';
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon,
} from '@material-ui/icons';

export const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleFirstPageButtonClick = () => onPageChange(0);
  const handleBackButtonClick = () => onPageChange(page - 1);
  const handleNextButtonClick = () => onPageChange(page + 1);
  const handleLastPageButtonClick = () =>
    onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box
      alignItems="center"
      display="flex"
      flexShrink={0}
      justifyContent="center"
    >
      <IconButton onClick={handleFirstPageButtonClick} disabled={!page}>
        <FirstPageIcon />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={!page}>
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};
