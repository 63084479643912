import { Box } from '@material-ui/core';

export const NotFound = () => (
  <Box
    alignItems="center"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    p={5}
    width={1}
  >
    <h3>We are sorry, but this page does not exist.</h3>
  </Box>
);
