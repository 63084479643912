import { Box, makeStyles, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@material-ui/icons/SearchRounded';
import { PaperInput } from 'components/PaperInput';
import { EMPTY_STRING } from 'constants/AppConstants';
import { Translate } from 'intl/Translate';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
  },
}));

export const GlobalSearch = ({ initialValue = EMPTY_STRING, onChange }) => {
  const classes = useStyles();
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      search: initialValue,
    },
  });

  return (
    <PaperInput>
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="row"
        px={2}
        py={1}
        width={1}
      >
        <form onSubmit={handleSubmit(onChange)} className={classes.form}>
          <Controller
            control={control}
            name="search"
            render={({ field }) => (
              <TextField
                fullWidth
                id="search"
                label={<Translate text="forms.search.placeholder" />}
                margin="none"
                type="search"
                {...field}
              />
            )}
          />
        </form>

        <SearchIcon />
      </Box>
    </PaperInput>
  );
};
