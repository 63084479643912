import { useHistory, useParams } from 'react-router-dom';
import { first, isEmpty, merge } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { useFetchCfpForms } from 'hooks/useFetchCfpForms';
import { useAddCfpForm } from 'hooks/useAddCfpForm';
import { EMPTY_STRING } from 'constants/AppConstants';
import { DefaultForm } from 'components/DefaultForm';
import { RoutePath } from 'constants/RoutePath';

const ERRONEOUS_HEADLINE = `We're sorry. We cannot identify the requested config.`;
const DUPLICATE_SUFFIX = `copy`;

export const Duplicate = () => {
  const { clientId } = useParams();
  const { push: navigate } = useHistory();

  const {
    isLoading: isFetchLoading,
    data,
    isError: isFetchFailed,
    isSuccess,
  } = useFetchCfpForms({
    queryParams: { search: clientId },
  });

  const existingFormConfig = useMemo(() => {
    const rawFormConfig = first(data?.forms);
    if (isEmpty(rawFormConfig)) {
      return rawFormConfig;
    }

    return merge({}, rawFormConfig, {
      client: {
        clientName: `${rawFormConfig?.client?.clientName} ${DUPLICATE_SUFFIX}`,
      },
    });
  }, [data?.forms]);

  const navigateToEdit = useCallback(
    () => navigate(RoutePath.ROOT),
    [navigate],
  );

  const { mutate: mutateByCreatingForm, isLoading: isMutateLoading } =
    useAddCfpForm({
      onSuccess: navigateToEdit,
    });

  const isErroneous =
    isFetchFailed || (isSuccess && isEmpty(existingFormConfig));
  const isLoading = isFetchLoading || isMutateLoading;
  const defaultHeadline = (
    <>
      <Typography variant="h4">Duplicating</Typography>
      <Typography variant="h6">
        {existingFormConfig?.client?.clientName || EMPTY_STRING}
      </Typography>
    </>
  );

  return (
    <DefaultForm
      applyButtonLabel="Duplicate"
      headline={isErroneous ? ERRONEOUS_HEADLINE : defaultHeadline}
      initialValues={existingFormConfig}
      isLoading={isLoading}
      isDisabled={isErroneous}
      onApply={mutateByCreatingForm}
    />
  );
};
