import { Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormField } from 'constants/FormField';
import { validateNotWhitespaceOnly, validateURL } from 'components/modules';

export const EventConfigurationFragment = ({ control, isDisabled }) => (
  <Box display="flex" flexDirection="column" width={1}>
    <Controller
      control={control}
      name={FormField.EXTERNAL_EVENT_ID}
      rules={{
        required: 'Event external ID is required.',
        validate: validateNotWhitespaceOnly,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.EXTERNAL_EVENT_ID}
          InputLabelProps={{ shrink: true }}
          label="External event ID"
          margin="dense"
          placeholder="e.g. cfp_dev_org"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.EVENT_NAME}
      rules={{
        required: 'Event name is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.EVENT_NAME}
          InputLabelProps={{ shrink: true }}
          label="Event name"
          margin="dense"
          placeholder="e.g. Custom event"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.OVERVIEW_IMAGE_URL}
      rules={{
        required: 'Overview image URL is required.',
        validate: validateURL,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.OVERVIEW_IMAGE_URL}
          InputLabelProps={{ shrink: true }}
          label="Overview image URL"
          margin="dense"
          placeholder="e.g. https://media.aws.keyper.io/events/cfp_event_image.png"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.ADDITIONAL_TEXT}
      render={({ field, fieldState: { error } }) => (
        <TextField
          disabled={isDisabled}
          error={!!error}
          fullWidth
          helperText={error?.message}
          id={FormField.ADDITIONAL_TEXT}
          InputLabelProps={{ shrink: true }}
          label="Additional text"
          margin="dense"
          variant="outlined"
          {...field}
        />
      )}
    />

    <Controller
      control={control}
      name={FormField.DO_PERSONALIZATION}
      rules={{
        required: 'Personalization selection is required.',
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          component="fieldset"
          fullWidth
          margin="dense"
          variant="filled"
          error={!!error}
        >
          <FormLabel component="legend">
            <Typography variant="body2">Personalization (yes/no)</Typography>
          </FormLabel>

          <RadioGroup {...field} id={FormField.DO_PERSONALIZATION}>
            <FormControlLabel
              control={<Radio size="small" />}
              disabled={isDisabled}
              label="Personalize (yes)"
              value={Boolean(true)}
            />
            <FormControlLabel
              control={<Radio size="small" />}
              disabled={isDisabled}
              label="Do not personalize (no)"
              value={Boolean(false)}
            />
          </RadioGroup>

          {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  </Box>
);
