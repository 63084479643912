import { ThemeProvider as ScThemeProvider } from 'styled-components';
import {
  createTheme as createMuiTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { Colour } from './Colour';
import { GlobalStyle } from './GlobalStyle';
import { ScThemeProperties } from './ScThemeProperties';

const theme = {
  [ScThemeProperties.MAIN]: Colour.main,
  [ScThemeProperties.TEXT]: Colour.text,
  [ScThemeProperties.RED]: Colour.red,
  [ScThemeProperties.ORANGE]: Colour.orange,
  [ScThemeProperties.MAIN_LIGHT]: Colour.mainLight,
};

const muiTheme = createMuiTheme({
  typography: {
    h5: {
      fontWeight: '500',
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    primary: {
      main: Colour.main,
    },
    secondary: {
      main: Colour.text,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiStepIcon: {
      root: {
        color: 'rgba(0, 0, 0, 0.10)',
      },
      active: {
        '& $text': {
          fill: 'white',
        },
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: '8px',
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 500,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1em',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        minWidth: 'unset',
      },
    },
  },
});

export const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <ScThemeProvider theme={theme}>
      <GlobalStyle />
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ScThemeProvider>
  </StylesProvider>
);
